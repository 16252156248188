export const flexEmployeesTranslations = {
  title: 'flex.employees.title',
  onboarding: 'flex.employees.onboarding',
  cta_create: 'flex.employees.cta_create',
  searchPlaceholder: 'flex.employees.search_placeholder',
  table: {
    title: 'flex.employees.table.title',
    header: {
      name: 'flex.employees.table.header.name',
      documentType: 'flex.employees.table.header.document_type',
      document: 'flex.employees.table.header.document',
      workingPercentage: 'flex.employees.table.header.working_percentage',
      collective: 'flex.employees.table.header.collective',
      communicationDate: 'flex.employees.table.header.communication_date',
      state: 'flex.employees.table.header.state',
      contract: 'flex.employees.table.header.contract',
      prfLong: 'flex.employees.table.header.prfLong',
      prfShort: 'flex.employees.table.header.prfShort',
      communicationSent: 'flex.employees.table.header.communicationSent',
      commSent: 'flex.employees.table.header.commSent',
      commPending: 'flex.employees.table.header.commPending',
      actions: 'flex.employees.table.header.actions',
    },
    body: {
      active: 'flex.employees.table.body.active',
      inactive: 'flex.employees.table.body.inactive',
      received: 'flex.employees.table.body.received',
      pending: 'flex.employees.table.body.pending',
      noAdd: 'flex.employees.table.body.noAdd',
      pendingPill: 'flex.employees.table.body.pendingPill',
      notStartedPrf: 'flex.employees.table.body.notStartedPrf',
      notStartedPrfTooltip: 'flex.employees.table.body.notStartedPrfTooltip',
      pendingPrf: 'flex.employees.table.body.pendingPrf',
      pendingPrfTooltip: 'flex.employees.table.body.pendingPrfTooltip',
      cancelledPrf: 'flex.employees.table.body.cancelledPrf',
      cancelledPrfTooltip: 'flex.employees.table.body.cancelledPrfTooltip',
      donePrf: 'flex.employees.table.body.donePrf',
      donePrfTooltip: 'flex.employees.table.body.donePrfTooltip',
    },
    noEmployees: 'flex.employees.table.no_employees',
    noEmployeesWithFilters: 'flex.employees.table.no_employees_with_filters',
    filters: {
      placeholders: {
        contract: 'flex.employees.configure.filter.contract_select.placeholder',
        prf: 'flex.employees.configure.filter.prf_select.placeholder',
      },
    },
  },
  deleteToolbar: {
    title: 'flex.employees.deleteToolbar.title',
    subtitle: 'flex.employees.deleteToolbar.subtitle',
    deleteEmployees: 'flex.employees.deleteToolbar.deleteEmployees',
    sendCommunications: 'flex.employees.deleteToolbar.sendCommunications',
    modal: {
      title1: 'flex.employees.deleteToolbar.modal.title1',
      title2: 'flex.employees.deleteToolbar.modal.title2',
      description1: 'flex.employees.deleteToolbar.modal.description1',
      description2: 'flex.employees.deleteToolbar.modal.description2',
      successMessage: 'flex.employees.deleteToolbar.modal.successMessage',
      errorMessage1: 'flex.employees.deleteToolbar.modal.errorMessage1',
      errorMessage2: 'flex.employees.deleteToolbar.modal.errorMessage2',
      errorMessage3: 'flex.employees.deleteToolbar.modal.errorMessage3',
    },
  },
  videotutorial: {
    byForm: 'flex.employees.videotutorial.by_form',
    byExcel: 'flex.employees.videotutorial.by_excel',
    editMultiple: 'flex.employees.videotutorial.edit_multiple',
  },
  subscribe: {
    cta: 'flex.employees.subscribe.cta',
    again: 'flex.employees.subscribe.again',
    success: 'flex.employees.subscribe.success',
  },
  unsubscribe: {
    cta: 'flex.employees.unsubscribe.cta',
    title: 'flex.employees.unsubscribe.title',
    description: 'flex.employees.unsubscribe.description',
    success: 'flex.employees.unsubscribe.success',
  },
  delete: {
    cta: 'flex.employees.delete.cta',
    tooltip: 'flex.employees.delete.tooltip',
    title: 'flex.employees.delete.title',
    titleSSI: 'flex.employees.delete.titleSSI',
    description: 'flex.employees.delete.description',
    descriptionSSI: 'flex.employees.delete.descriptionSSI',
    success: 'flex.employees.delete.success',
    error: 'flex.employees.delete.error',
  },

  configure: {
    create: {
      title: 'flex.employees.configure.create.title',
    },
    edit: {
      title: 'flex.employees.configure.edit.title',
    },
    delete: {
      iconLabel: 'flex.employees.configure.delete.icon_label',
      form: {
        title: 'flex.employees.configure.delete.form.title',
        inactive: 'flex.employees.configure.delete.form.inactive',
        active: 'flex.employees.configure.delete.form.active',
      },
    },
    tabs: {
      prf: 'flex.employees.configure.tabs.prf',
      products: 'flex.employees.configure.tabs.products',
    },
    acceptContract: {
      iconLabel: 'flex.employees.configure.accept_contract.icon_label',
      form: {
        title: 'flex.employees.configure.accept_contract.form.title',
        description: 'flex.employees.configure.accept_contract.form.description',
        cta: 'flex.employees.configure.accept_contract.form.cta',
      },
    },
    common: {
      form: {
        title_basic: 'flex.employees.configure.common.form.title_basic',
        title_retribution: 'flex.employees.configure.common.form.title_retribution',
        title_delivery_point: 'flex.employees.configure.common.form.title_delivery_point',
        title_communication: 'flex.employees.configure.common.form.title_communication',
        edit: {
          label: 'flex.employees.configure.common.form.edit.label',
        },
        conditions: {
          label: 'flex.employees.configure.common.form.accept_conditions.label',
          link: 'flex.employees.configure.common.form.accept_conditions.link',
          filename: 'flex.employees.configure.common.form.accept_conditions.filename',
        },
        title: 'flex.employees.configure.common.form.title',
        name: {
          label: 'flex.employees.configure.common.form.name.label',
          placeholder: 'flex.employees.configure.common.form.name.placeholder',
        },
        firstSurname: {
          label: 'flex.employees.configure.common.form.first_surname.label',
          placeholder: 'flex.employees.configure.common.form.first_surname.placeholder',
        },
        secondSurname: {
          label: 'flex.employees.configure.common.form.second_surname.label',
          placeholder: 'flex.employees.configure.common.form.second_surname.placeholder',
        },
        documentType: {
          label: 'flex.employees.configure.common.form.document_type.label',
          placeholder: 'flex.employees.configure.common.form.document_type.placeholder',
          options: {
            dni: 'flex.employees.configure.common.form.document_type.dni',
            passport: 'flex.employees.configure.common.form.document_type.passport',
          },
          legend: 'flex.employees.configure.common.form.document_type.legend',
        },
        documentNumber: {
          label: 'flex.employees.configure.common.form.document_number.label',
          placeholder: 'flex.employees.configure.common.form.document_number.placeholder',
          tooltip: {
            title: 'flex.employees.configure.common.form.document_number.tooltip',
            item1: 'flex.employees.configure.common.form.document_number.tooltip.item1',
            item2: 'flex.employees.configure.common.form.document_number.tooltip.item2',
          },
        },
        birthDate: {
          label: 'flex.employees.configure.common.form.birth_date.label',
        },
        email: {
          label: 'flex.employees.configure.common.form.email.label',
          placeholder: 'flex.employees.configure.common.form.email.placeholder',
          legend: 'flex.employees.configure.common.form.email.legend',
        },
        phoneNumber: {
          label: 'flex.employees.configure.common.form.phone_number.label',
          placeholder: 'flex.employees.configure.common.form.phone_number.placeholder',
        },
        employeeCenter: {
          label: 'flex.employees.configure.common.form.employee_center.label',
          placeholder: 'flex.employees.configure.common.form.employee_center.placeholder',
        },
        employeeNumber: {
          label: 'flex.employees.configure.common.form.employee_number.label',
          placeholder: 'flex.employees.configure.common.form.employee_number.placeholder',
        },
        collective: {
          label: 'flex.employees.configure.common.form.collective.label',
          placeholder: 'flex.employees.configure.common.form.collective.placeholder',
          legend: 'flex.employees.configure.common.form.collective.legend',
        },
        ssNumber: {
          label: 'flex.employees.configure.common.form.ss_number.label',
          placeholder: 'flex.employees.configure.common.form.ss_number.placeholder',
        },
        workingPercentage: {
          label: 'flex.employees.configure.common.form.working_percentage.label',
          placeholder:
            'flex.employees.configure.common.form.working_percentage.placeholder',
          tooltip: 'flex.employees.configure.common.form.working_percentage.tooltip',
        },
        grossSalary: {
          label: 'flex.employees.configure.common.form.gross_salary.label',
          placeholder: 'flex.employees.configure.common.form.gross_salary.placeholder',
          tooltip: 'flex.employees.configure.common.form.gross_salary.tooltip',
        },
        kindSalary: {
          label: 'flex.employees.configure.common.form.kind_salary.label',
          placeholder: 'flex.employees.configure.common.form.kind_salary.placeholder',
          tooltip: 'flex.employees.configure.common.form.kind_salary.tooltip',
        },
        irpf: {
          label: 'flex.employees.configure.common.form.irpf.label',
          placeholder: 'flex.employees.configure.common.form.irpf.placeholder',
          tooltip: 'flex.employees.configure.common.form.irpf.tooltip',
        },
      },
    },
    calculator: {
      percentage: 'flex.employees.configure.calculator.percentage',
      title: 'flex.employees.configure.calculator.title',
      subtitle: 'flex.employees.configure.calculator.subtitle',
      details: {
        introduction: 'flex.employees.configure.calculator.details.introduction',
        bullets: {
          first: {
            field: 'flex.employees.configure.calculator.details.bullets.first.field',
            value: 'flex.employees.configure.calculator.details.bullets.first.value',
          },
          second: {
            field: 'flex.employees.configure.calculator.details.bullets.second.field',
            value: 'flex.employees.configure.calculator.details.bullets.second.value',
          },
          third: {
            field: 'flex.employees.configure.calculator.details.bullets.third.field',
            value: 'flex.employees.configure.calculator.details.bullets.third.value',
          },
        },
        disclaimer: 'flex.employees.configure.calculator.details.disclaimer',
      },
    },
    communication: {
      cta: {
        label: 'flex.employees.configure.communication.cta.label',
        yes: 'flex.employees.configure.communication.cta.yes',
        no: 'flex.employees.configure.communication.cta.no',
      },
    },
    products: {
      title: 'flex.employees.configure.products.title',
      description: 'flex.employees.configure.products.description',
      noProducts: 'flex.employees.configure.products.noProducts',
      table: {
        head: {
          name: 'flex.employees.configure.products.table.head.name',
          detail: 'flex.employees.configure.products.table.head.detail',
          actions: 'flex.employees.configure.products.table.head.actions',
        },
        details: {
          cardType: 'flex.employees.configure.products.table.details.cardType',
          contractDate: 'flex.employees.configure.products.table.details.contractDate',
          startDate: 'flex.employees.configure.products.table.details.startDate',
          monthsAndAmounts:
            'flex.employees.configure.products.table.details.monthsAndAmounts',
          monthLimit: 'flex.employees.configure.products.table.details.monthLimit',
          anualLimit: 'flex.employees.configure.products.table.details.anualLimit',
          useMonths: 'flex.employees.configure.products.table.details.useMonths',
          birthDate: 'flex.employees.configure.products.table.details.birthDate',
          childrenName: 'flex.employees.configure.products.table.details.childrenName',
          kindergarten: 'flex.employees.configure.products.table.details.kindergarten',
          anualContractAmount:
            'flex.employees.configure.products.table.details.anualContractAmount',
          maxAvailableAmount:
            'flex.employees.configure.products.table.details.maxAvailableAmount',
          trainingName: 'flex.employees.configure.products.table.details.trainingName',
          schoolName: 'flex.employees.configure.products.table.details.schoolName',
          trainingTotalAmount:
            'flex.employees.configure.products.table.details.trainingTotalAmount',
          splitPayment: 'flex.employees.configure.products.table.details.splitPayment',
          pendingPaymentAmount:
            'flex.employees.configure.products.table.details.pendingPaymentAmount',
          name: 'flex.employees.configure.products.table.details.name',
          beneficiary: 'flex.employees.configure.products.table.details.beneficiary',
          notBeneficiary:
            'flex.employees.configure.products.table.details.notBeneficiary',
          relativeType: 'flex.employees.configure.products.table.details.relativeType',
          company: 'flex.employees.configure.products.table.details.company',
          type: 'flex.employees.configure.products.table.details.type',
          monthAmount: 'flex.employees.configure.products.table.details.monthAmount',
          policyName: 'flex.employees.configure.products.table.details.policyName',
        },
      },
    },
  },
  excel: {
    button1: {
      label: 'flex.employees.excel.button1.label',
      subtitle: 'flex.employees.excel.button1.subtitle',
    },
    button2: {
      label: 'flex.employees.excel.button2.label',
      subtitle: 'flex.employees.excel.button2.subtitle',
    },
    errorPill: {
      error: 'flex.employees.excel.error_pill.error',
      warning: 'flex.employees.excel.error_pill.warning',
    },
    uploadError: 'flex.employees.excel.upload_error',
    downloadError: 'flex.employees.excel.download_error',
    confirmError: 'flex.employees.excel.confirm_error',
    confirmSuccess: {
      title: 'flex.employees.excel.confirm_success.title',
      subtitle: 'flex.employees.excel.confirm_success.subtitle',
      button: 'flex.employees.excel.confirm_success.button',
    },
    download: {
      button: 'flex.employees.excel.download.button',
      buttonEmpty: 'flex.employees.excel.download.button_empty',
      fileName: 'flex.employees.excel.download.fileName',
      fileNameEmpty: 'flex.employees.excel.download.fileName_empty',
    },
    validation: {
      label: 'flex.employees.excel.validation.label',
      table: {
        title: 'flex.employees.excel.validation.table.title',
        header: {
          concepts: 'flex.employees.excel.validation.table.header.concepts',
          units: 'flex.employees.excel.validation.table.header.units',
        },
        body: {
          add: 'flex.employees.excel.validation.table.body.add',
          up: 'flex.employees.excel.validation.table.body.up',
          down: 'flex.employees.excel.validation.table.body.down',
          modify: 'flex.employees.excel.validation.table.body.modify',
          remove: 'flex.employees.excel.validation.table.body.remove',
        },
      },
    },
    layout: {
      title: 'flex.employees.excel.layout.title',
      step1: 'flex.employees.excel.layout.step1',
      step2: 'flex.employees.excel.layout.step2',
      step3: 'flex.employees.excel.layout.step3',
      button: {
        cancel: 'flex.employees.excel.layout.button.cancel',
      },
    },
    upload: {
      label: 'flex.employees.excel.upload.label',
      steps: {
        title: 'flex.employees.excel.upload.steps.title',
        firstStep: 'flex.employees.excel.upload.steps.first_step',
        secondStep: 'flex.employees.excel.upload.steps.second_step',
        thirdStep: 'flex.employees.excel.upload.steps.third_step',
      },
      description: 'flex.employees.excel.upload.description',
      description_2: 'flex.employees.excel.upload.description_2',
      description_3: 'flex.employees.excel.upload.description_3',
      option: {
        firstOption: 'flex.employees.excel.upload.option.first_option',
        secondOption: 'flex.employees.excel.upload.option.second_option',
        thirdOption: 'flex.employees.excel.upload.option.third_option',
        fourthOption: 'flex.employees.excel.upload.option.fourth_option',
      },
      errors: {
        label: 'flex.employees.excel.upload.errors.label',
        title: 'flex.employees.excel.upload.errors.title',
        subtitle: 'flex.employees.excel.upload.errors.subtitle',
        list1: 'flex.employees.excel.upload.errors.list1',
        list2: 'flex.employees.excel.upload.errors.list2',
        table: {
          title: 'flex.employees.excel.upload.errors.table.title',
          header: {
            title: 'flex.employees.excel.upload.errors.table.header.row',
            kind: 'flex.employees.excel.upload.errors.table.header.kind',
            description: 'flex.employees.excel.upload.errors.table.header.description',
          },
        },
        button1: 'flex.employees.excel.upload.errors.button1',
        button2: 'flex.employees.excel.upload.errors.button2',
      },
    },
  },
}
