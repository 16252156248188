import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  OreDatePicker,
  OreHeading,
  OreInput,
  OreSelect,
  OreSelectPlaceholder,
  OreStack,
  OreText,
  OreTooltip,
  OreWysiwyg,
} from '@runroom/oreneta'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'
import { documentType } from 'src/domain/enum'
import { bornDate } from 'src/core/helpers'
import {
  ConfigureEmployeesDelivery,
  EmployeeForm,
  TooltipList,
} from 'src/Flex/Employees/ui/employee-detail'
import { handleErrors } from 'src/presentation/sharedForms/helpers'
import { useParams } from 'react-router-dom'

export const ConfigureEmployeesBasicForm = (): JSX.Element => {
  const { t } = useTranslation()
  const { register, formState } = useFormContext<EmployeeForm>()
  const { errors } = handleErrors(formState)
  const { id: employeeId } = useParams()

  return (
    <OreStack placeContent="stretch" space="medium">
      <OreHeading as="h2" size="headline-md">
        {t(flexEmployeesTranslations.configure.common.form.title_basic)}
      </OreHeading>
      <OreInput
        {...register('userFirstName')}
        {...errors('userFirstName')}
        id="employeeName"
        label={t(flexEmployeesTranslations.configure.common.form.name.label)}
        placeholder={t(flexEmployeesTranslations.configure.common.form.name.placeholder)}
        required
      />
      <OreStack space="medium" direction="row" sameSize>
        <OreInput
          {...register('userLastName')}
          {...errors('userLastName')}
          id="employeeFirstSurname"
          label={t(flexEmployeesTranslations.configure.common.form.firstSurname.label)}
          placeholder={t(
            flexEmployeesTranslations.configure.common.form.firstSurname.placeholder
          )}
          required
        />
        <OreInput
          {...register('userLastName2')}
          {...errors('userLastName2')}
          id="employeeSecondSurname"
          label={t(flexEmployeesTranslations.configure.common.form.secondSurname.label)}
          placeholder={t(
            flexEmployeesTranslations.configure.common.form.secondSurname.placeholder
          )}
        />
      </OreStack>
      <OreStack space="medium" direction="row" sameSize>
        <OreSelect
          {...register('documentType')}
          {...errors('documentType')}
          id="documentType"
          label={t(flexEmployeesTranslations.configure.common.form.documentType.label)}
          required
          disabled={!!employeeId}>
          <OreSelectPlaceholder
            label={t(
              flexEmployeesTranslations.configure.common.form.documentType.placeholder
            )}
          />
          <option value={documentType.DniNie}>
            {t(flexEmployeesTranslations.configure.common.form.documentType.options.dni)}
          </option>
          <option value={documentType.Passport}>
            {t(
              flexEmployeesTranslations.configure.common.form.documentType.options
                .passport
            )}
          </option>
        </OreSelect>
        {!employeeId ? (
          <OreInput
            {...register('document')}
            {...errors('document')}
            id="documentNumber"
            label={t(
              flexEmployeesTranslations.configure.common.form.documentNumber.label
            )}
            placeholder={t(
              flexEmployeesTranslations.configure.common.form.documentNumber.placeholder
            )}
            required
          />
        ) : (
          <OreTooltip text={<TooltipList />} placement="top">
            <OreInput
              {...register('document')}
              {...errors('document')}
              id="documentNumber"
              label={t(
                flexEmployeesTranslations.configure.common.form.documentNumber.label
              )}
              placeholder={t(
                flexEmployeesTranslations.configure.common.form.documentNumber.placeholder
              )}
              required
              disabled={!!employeeId}
            />
          </OreTooltip>
        )}
      </OreStack>
      <OreDatePicker
        {...register('birthday')}
        {...errors('birthday')}
        id="birthday"
        label={t(flexEmployeesTranslations.configure.common.form.birthDate.label)}
        required
        max={bornDate(16)}
      />
      <OreStack space="medium" direction="row" sameSize>
        <OreInput
          {...register('email')}
          {...errors('email')}
          id="email"
          label={t(flexEmployeesTranslations.configure.common.form.email.label)}
          type="email"
          placeholder={t(
            flexEmployeesTranslations.configure.common.form.email.placeholder
          )}
          required
        />
        <OreInput
          {...register('telephone')}
          {...errors('telephone')}
          id="telephone"
          label={t(flexEmployeesTranslations.configure.common.form.phoneNumber.label)}
          type="tel"
          placeholder={t(
            flexEmployeesTranslations.configure.common.form.phoneNumber.placeholder
          )}
        />
      </OreStack>
      <OreStack space="medium" direction="row" sameSize>
        <OreInput
          {...register('costCenter')}
          {...errors('costCenter')}
          id="costCenter"
          label={t(flexEmployeesTranslations.configure.common.form.employeeCenter.label)}
          placeholder={t(
            flexEmployeesTranslations.configure.common.form.employeeCenter.placeholder
          )}
        />
        <OreInput
          {...register('employeeNumber')}
          {...errors('employeeNumber')}
          id="employeeNumber"
          label={t(flexEmployeesTranslations.configure.common.form.employeeNumber.label)}
          placeholder={t(
            flexEmployeesTranslations.configure.common.form.employeeNumber.placeholder
          )}
        />
      </OreStack>
      <OreStack space="medium" direction="row" sameSize>
        <OreInput
          {...register('socialSecurityNumber')}
          {...errors('socialSecurityNumber')}
          id="socialSecurityNumber"
          label={t(flexEmployeesTranslations.configure.common.form.ssNumber.label)}
          placeholder={t(
            flexEmployeesTranslations.configure.common.form.ssNumber.placeholder
          )}
        />
        <OreInput
          {...register('irpf')}
          {...errors('irpf')}
          startAdornment="%"
          id="irpf"
          type="number"
          label={t(flexEmployeesTranslations.configure.common.form.irpf.label)}
          labelTooltip={t(flexEmployeesTranslations.configure.common.form.irpf.tooltip)}
          placeholder={t(
            flexEmployeesTranslations.configure.common.form.irpf.placeholder
          )}
          required
        />
      </OreStack>
      <ConfigureEmployeesDelivery />
    </OreStack>
  )
}
